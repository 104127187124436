import React from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import scrollTo from "gatsby-plugin-smoothscroll";
export default function Secsix() {
  return (
    <>
      <Container fluid className="__bg-secondary-sl">
        <Container>
          <Row className="__bg-thumbnail-up-shadow">
            <Col lg={4} md={4}>
              <div className="__image-new-thumbnail-up">
                <img
                  src="https://joseaditya.sgp1.cdn.digitaloceanspaces.com/images/lovetraining.id/commitment-mastery/%5BMockupGrilSmille%5D-CM.png"
                  className="img img-fluid"
                ></img>
              </div>
            </Col>
            <Col lg={8} md={8}>
              <div className="__text-new-thumbnail-up">
                <h2>
                  Mengapa Commitment Mastery berguna untuk mereka yang belum
                  punya pasangan?
                </h2>
                <p>
                  Agar wanita single mengerti apa yang membuat pria inginkan
                  komitmen, dan mentriggernya pada calon pasangan, sehingga
                  hubungan bisa berjalan relatif mudah ke jenjang pernikahan.
                </p>
              </div>
            </Col>
          </Row>
          <Row className="__bg-thumbnail-up-shadow">
            <Col lg={4} md={4}>
              <div className="__image-new-thumbnail-up">
                <img
                  src="https://joseaditya.sgp1.cdn.digitaloceanspaces.com/images/lovetraining.id/commitment-mastery/%5BMockupCouple%5D-CM.png"
                  className="img img-fluid"
                ></img>
              </div>
            </Col>
            <Col lg={8} md={8}>
              <div className="__text-new-thumbnail-up">
                <h2>
                  Bagaimana Commitment Mastery juga bisa membantu mereka yang
                  sudah punya pasangan?
                </h2>
                <p>
                  Ikuti Commitment Mastery bisa mempererat dan membangun
                  hubungan yang didasari komitmen yang kuat, meredam potensi
                  konflik, dan hidup dalam kebahagiaan yang tanpa drama.
                </p>
                <p>
                  Bayangkan ketika, kalian hanya “terikat komitmen” pernikahan
                  namun dirinya tidak kunjung komit mengubah sikapnya yang tidak
                  kamu sukai?
                </p>
              </div>
            </Col>
          </Row>
        </Container>
        <Container className="m-100">
          <Row className="fade-in">
            <Col lg={12}>
              <div className="__wrappe-headline-sl">
                <h2 className="text-center">
                  Untuk Menyelesaikan Pendaftaran :
                </h2>
                <h2 className="text-center">Pilih Durasi Membershipmu</h2>
              </div>
            </Col>
          </Row>

          <Row id="buy-bottom">
            <Col lg={4} md={4}>
              <div className="pricing py-5">
                <div className="card mb-5 mb-lg-0 up-cover">
                  <div className="card-body">
                    <h5 className="card-title text-muted text-uppercase text-center">
                      VIP Membership <br /> 1 BULAN
                    </h5>
                    <h6 className="card-price text-center">
                      <del className="__style-red-text">Rp 212.000</del> <br />{" "}
                      Rp 199.000
                    </h6>
                    <h5 className="card-price-v text-center">Hemat 7%</h5>

                    <hr />
                    <ul className="fa-ul">
                      <li>
                        <span className="fa-li">
                          <FontAwesomeIcon
                            icon={faCheckCircle}
                            className="__fa-check-circle-sl"
                            style={{ color: "green" }}
                          />
                        </span>
                        <b>ALL Access VIP Course</b>
                      </li>

                      <li>
                        <span className="fa-li">
                          <FontAwesomeIcon
                            icon={faCheckCircle}
                            className="__fa-check-circle-sl"
                            style={{ color: "green" }}
                          />
                        </span>
                        <b>ALL Access Premium Course</b>
                      </li>
                      <li>
                        <span className="fa-li">
                          <FontAwesomeIcon
                            icon={faCheckCircle}
                            className="__fa-check-circle-sl"
                            style={{ color: "green" }}
                          />
                        </span>
                        <b>ALL Access FREE Course​</b>
                      </li>
                      <li>
                        <span className="fa-li">
                          <FontAwesomeIcon
                            icon={faCheckCircle}
                            className="__fa-check-circle-sl"
                            style={{ color: "green" }}
                          />
                        </span>
                        <b>Monthly QnA With Coaches​</b>
                      </li>
                      <li>
                        <span className="fa-li">
                          <FontAwesomeIcon
                            icon={faCheckCircle}
                            className="__fa-check-circle-sl"
                            style={{ color: "green" }}
                          />
                        </span>
                        <b>Exclusive Live Webinar​</b>
                      </li>
                      <li>
                        <span className="fa-li">
                          <FontAwesomeIcon
                            icon={faCheckCircle}
                            className="__fa-check-circle-sl"
                            style={{ color: "green" }}
                          />
                        </span>
                        <b>Support system by LavSquad​</b>
                      </li>
                      <li>
                        <span className="fa-li">
                          <FontAwesomeIcon
                            icon={faCheckCircle}
                            className="__fa-check-circle-sl"
                            style={{ color: "green" }}
                          />
                        </span>
                        <b>Group Challenge​​</b>
                      </li>
                    </ul>
                    <h5 className="card-price-v-v text-center">
                      Semua benefit diatas selama 1 bulan
                    </h5>
                    <a
                      href="https://cart.lovecoach.id/product/vip-1-bulan-thr-spesial-offer"
                      target="_blank"
                      className="btn btn-block btn-primary text-uppercase btn-pop-hover"
                    >
                      Join Sekarang
                    </a>
                    {/* <a
                      href="https://wa.link/14x82j"
                      target="_blank"
                      className="btn btn-block btn-success text-uppercase btn-pop-hover"
                    >
                      Chat Cs Disini
                    </a> */}
                  </div>
                </div>
              </div>
            </Col>
            <Col lg={4} md={4}>
              <div className="pricing py-5">
                <div className="card mb-5 mb-lg-0 up-cover">
                  <div className="card-body">
                    <h5 className="card-title text-muted text-uppercase text-center">
                      VIP Membership <br /> 3 BULAN
                    </h5>
                    <h6 className="card-price text-center">
                      <del className="__style-red-text">Rp 636.000</del> <br />{" "}
                      Rp 499.000
                    </h6>
                    <h5 className="card-price-v text-center">Hemat 20%</h5>
                    <hr />
                    <ul className="fa-ul">
                      <li>
                        <span className="fa-li">
                          <FontAwesomeIcon
                            icon={faCheckCircle}
                            className="__fa-check-circle-sl"
                            style={{ color: "green" }}
                          />
                        </span>
                        <b>ALL Access VIP Course</b>
                      </li>

                      <li>
                        <span className="fa-li">
                          <FontAwesomeIcon
                            icon={faCheckCircle}
                            className="__fa-check-circle-sl"
                            style={{ color: "green" }}
                          />
                        </span>
                        <b>ALL Access Premium Course</b>
                      </li>
                      <li>
                        <span className="fa-li">
                          <FontAwesomeIcon
                            icon={faCheckCircle}
                            className="__fa-check-circle-sl"
                            style={{ color: "green" }}
                          />
                        </span>
                        <b>ALL Access FREE Course​</b>
                      </li>
                      <li>
                        <span className="fa-li">
                          <FontAwesomeIcon
                            icon={faCheckCircle}
                            className="__fa-check-circle-sl"
                            style={{ color: "green" }}
                          />
                        </span>
                        <b>Monthly QnA With Coaches​</b>
                      </li>
                      <li>
                        <span className="fa-li">
                          <FontAwesomeIcon
                            icon={faCheckCircle}
                            className="__fa-check-circle-sl"
                            style={{ color: "green" }}
                          />
                        </span>
                        <b>Exclusive Live Webinar​</b>
                      </li>
                      <li>
                        <span className="fa-li">
                          <FontAwesomeIcon
                            icon={faCheckCircle}
                            className="__fa-check-circle-sl"
                            style={{ color: "green" }}
                          />
                        </span>
                        <b>Support system by LavSquad​</b>
                      </li>
                      <li>
                        <span className="fa-li">
                          <FontAwesomeIcon
                            icon={faCheckCircle}
                            className="__fa-check-circle-sl"
                            style={{ color: "green" }}
                          />
                        </span>
                        <b>Group Challenge​​</b>
                      </li>
                    </ul>
                    <h5 className="card-price-v-v text-center">
                      Semua benefit diatas selama 3 bulan
                    </h5>
                    <a
                      href="https://cart.lovecoach.id/product/vip-3-bulan-thr-spesial-offer"
                      target="_blank"
                      className="btn btn-block btn-primary text-uppercase btn-pop-hover"
                    >
                      Join Sekarang
                    </a>
                    {/* <a
                      href="https://wa.link/14x82j"
                      target="_blank"
                      className="btn btn-block btn-success text-uppercase btn-pop-hover"
                    >
                      Chat Cs Disini
                    </a> */}
                  </div>
                </div>
              </div>
            </Col>
            <Col lg={4} md={4}>
              <div className="pricing py-5">
                <div className="card mb-5 mb-lg-0 up-cover">
                  <div className="card-body">
                    <h5 className="card-title text-muted text-uppercase text-center">
                      VIP Membership <br /> 12 BULAN
                    </h5>
                    <h6 className="card-price text-center">
                      <del className="__style-red-text">Rp 2.200.000</del>{" "}
                      <br /> Rp 1.499.000
                    </h6>
                    <h5 className="card-price-v text-center">Hemat 30%</h5>
                    <hr />
                    <ul className="fa-ul">
                      <li>
                        <span className="fa-li">
                          <FontAwesomeIcon
                            icon={faCheckCircle}
                            className="__fa-check-circle-sl"
                            style={{ color: "green" }}
                          />
                        </span>
                        <b>ALL Access VIP Course</b>
                      </li>

                      <li>
                        <span className="fa-li">
                          <FontAwesomeIcon
                            icon={faCheckCircle}
                            className="__fa-check-circle-sl"
                            style={{ color: "green" }}
                          />
                        </span>
                        <b>ALL Access Premium Course</b>
                      </li>
                      <li>
                        <span className="fa-li">
                          <FontAwesomeIcon
                            icon={faCheckCircle}
                            className="__fa-check-circle-sl"
                            style={{ color: "green" }}
                          />
                        </span>
                        <b>ALL Access FREE Course​</b>
                      </li>
                      <li>
                        <span className="fa-li">
                          <FontAwesomeIcon
                            icon={faCheckCircle}
                            className="__fa-check-circle-sl"
                            style={{ color: "green" }}
                          />
                        </span>
                        <b>Monthly QnA With Coaches​</b>
                      </li>
                      <li>
                        <span className="fa-li">
                          <FontAwesomeIcon
                            icon={faCheckCircle}
                            className="__fa-check-circle-sl"
                            style={{ color: "green" }}
                          />
                        </span>
                        <b>Exclusive Live Webinar​</b>
                      </li>
                      <li>
                        <span className="fa-li">
                          <FontAwesomeIcon
                            icon={faCheckCircle}
                            className="__fa-check-circle-sl"
                            style={{ color: "green" }}
                          />
                        </span>
                        <b>Support system by LavSquad​</b>
                      </li>
                      <li>
                        <span className="fa-li">
                          <FontAwesomeIcon
                            icon={faCheckCircle}
                            className="__fa-check-circle-sl"
                            style={{ color: "green" }}
                          />
                        </span>
                        <b>Group Challenge​​</b>
                      </li>
                    </ul>
                    <h5 className="card-price-v-v text-center">
                      Semua benefit diatas selama 12 bulan
                    </h5>
                    <a
                      href="https://cart.lovecoach.id/product/vip-12-bulan-thr-spesial-offer"
                      target="_blank"
                      className="btn btn-block btn-primary text-uppercase btn-pop-hover"
                    >
                      Join Sekarang
                    </a>
                    {/* <a
                      href="https://wa.link/14x82j"
                      target="_blank"
                      className="btn btn-block btn-success text-uppercase btn-pop-hover"
                    >
                      Chat Cs Disini
                    </a> */}
                  </div>
                </div>
              </div>
            </Col>
          </Row>

          <Row>
            <Col lg={12} md={12} sm={12} xs={12}>
              <div className="__bg-white-rounded-sl __margin-top-sl-50">
                <Row className="fade-in">
                  <Col lg={12}>
                    <div className="__wrappe-headline-sl">
                      <h3 className="text-center">
                        <b>
                          Semakin cepat kamu join Commitment Mastery semakin
                          cepat kamu bisa:
                        </b>
                      </h3>
                    </div>
                  </Col>

                  <Col lg={12} md={12} sm={12} xs={12}>
                    <div className="d-flex">
                      <div className="__width-icon-and-text">
                        <FontAwesomeIcon
                          icon={faCheckCircle}
                          className="__fa-check-circle-sl"
                          style={{ color: "green" }}
                        />
                      </div>
                      <p className="__text-desc-icon-sl">
                        <b>
                          Mengerti bagaimana cara agar pria mau komit sama kamu
                        </b>
                      </p>
                    </div>
                  </Col>

                  <Col lg={12} md={12} sm={12} xs={12}>
                    <div className="d-flex">
                      <div className="__width-icon-and-text">
                        <FontAwesomeIcon
                          icon={faCheckCircle}
                          className="__fa-check-circle-sl"
                          style={{ color: "green" }}
                        />
                      </div>
                      <p className="__text-desc-icon-sl">
                        <b>Terhindar dari cara yang salah</b> dan malah jadi
                        bubar
                      </p>
                    </div>
                  </Col>

                  <Col lg={12} md={12} sm={12} xs={12}>
                    <div className="d-flex">
                      <div className="__width-icon-and-text">
                        <FontAwesomeIcon
                          icon={faCheckCircle}
                          className="__fa-check-circle-sl"
                          style={{ color: "green" }}
                        />
                      </div>
                      <p className="__text-desc-icon-sl">
                        Terhindar dari kehidupan romansa yang tak tentu arah,
                        <b>tanpa kepastian</b>
                      </p>
                    </div>
                  </Col>

                  <Col lg={12} md={12} sm={12} xs={12}>
                    <div className="d-flex">
                      <div className="__width-icon-and-text">
                        <FontAwesomeIcon
                          icon={faCheckCircle}
                          className="__fa-check-circle-sl"
                          style={{ color: "green" }}
                        />
                      </div>
                      <p className="__text-desc-icon-sl">
                        Tahu kapan waktu yang tepat untuk{" "}
                        <b>berbicara tentang komitmen</b>
                      </p>
                    </div>
                  </Col>

                  <Col lg={12} md={12} sm={12} xs={12}>
                    <div className="d-flex">
                      <div className="__width-icon-and-text">
                        <FontAwesomeIcon
                          icon={faCheckCircle}
                          className="__fa-check-circle-sl"
                          style={{ color: "green" }}
                        />
                      </div>
                      <p className="__text-desc-icon-sl">
                        Menjadi wanita cerdas yang{" "}
                        <b>tidak dipermainkan cowok modus</b>
                      </p>
                    </div>
                  </Col>

                  <Col lg={12}>
                    <div className="d-flex justify-content-center wl-am-fr mt-5">
                      <Button
                        variant="primary"
                        size="lg"
                        onClick={() => scrollTo("#buy-bottom")}
                      >
                        YA SAYA MAU DAFTAR
                      </Button>{" "}
                    </div>
                    <p
                      className="text-center pt-3 __text-link"
                      onClick={() => scrollTo("#buy-bottom")}
                    >
                      <u>( klik disini untuk instan akses )</u>
                    </p>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </Container>
      </Container>
    </>
  );
}
